import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
//components
import Layout from '../components/Layout';
import HomeDesktop from '../components/Desktop/Home';
import HomeMobile from '../components/Mobile/Home';
//actions
import { setMetaTags } from '../actions/homeActions';
//styles
import './style.css';
//helpers

function Home({ data , setMetaTags }){
  const homePageData = data?.prismic?.home_page;

  useEffect(() => {
    const description = 'SoBridely - Home';
    const image = 'https://firebasestorage.googleapis.com/v0/b/sobridely-dev.appspot.com/o/Screen%20Shot%202020-05-27%20at%203.45.25%20AM.png?alt=media&token=19f7ea72-6c7f-4da8-9910-480a421af477';
    const url = window.location.href;
    const title = `SoBridely`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);
  return (
    <Layout
      pageData={homePageData}
      showFooter={false}
      mobile={<HomeMobile />}
      desktop={<HomeDesktop />} />
  );
}

const mapDispatchToProps = { setMetaTags };

export default connect(null, mapDispatchToProps)(Home);

export const query = graphql`
query {
  prismic {
    home_page(uid: "home", lang: "en-us") {
      title
      hero_image
      hero_top_text
      hero_bottom_text
      hero_body_text
      shop_button_text
      shop_by_silhouette
      featured_wedding_dresses
      _linkType
      silhouettes {
        image
        label
      }
    }
  }
}
`;