import React from 'react';
import { navigate } from '@reach/router';
import {
  Container, Title, Heading,
  Line, Dresses, DressImg,
  DressTitle, DressContainer,
} from './styled.js';

function ShopBy({ shop_by_silhouette, silhouettes = [] }) {
  return (
    <Container>
      <Heading>
        <Line style={{ marginLeft: 125, marginRight: 40 }} />
        <Title>{shop_by_silhouette}</Title>
        <Line style={{ marginRight: 125, marginLeft: 40 }} />
      </Heading>
      <Dresses>
        {
          silhouettes.map(({ image, label }, index) => (
            <DressContainer onClick={() => navigate(`/shop?search=${label}`)} key={index}>
              <DressImg src={image?.url} />
              <DressTitle>{label}</DressTitle>
            </DressContainer>
          ))
        }
      </Dresses>
    </Container>
  );
}

export default ShopBy;