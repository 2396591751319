import React from 'react';
import NavLogo from '../../../../NavLogo';
import { Container } from './styled';

function BoxDialog({ top, bottom }) {
  return (
    <Container>
      <NavLogo bottomStyle={{ fontSize: '4.000vw' }} topStyle={{ fontSize: '5.938vw' }} top={top} bottom={bottom} heart={false} />
    </Container>
  );
}

export default BoxDialog;
