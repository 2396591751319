import React, { useContext } from 'react';
import Footer from '../Footer';
import { AppContext } from '../../AppProvider';
import Hero from './Hero/';
import ShopBy from './ShopBy';
import Analytics from './Analytics';
import Featured from './Featured';

function Home(props) {
  const { data } = useContext(AppContext);
  return (
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Hero {...data} {...props} />
      <ShopBy {...data} {...props} />
      <Analytics {...data} {...props} />
      <Featured {...data} {...props} />
      <Footer />
    </div>
  );
}

export default Home;