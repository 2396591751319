import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

`;

export const SubHeadingContainer = styled.div`
  height: 61.25vw;
  background: #D8C4C2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SubHeadingContainerTitle = styled.div`
  font-family: SilverSouthScript;
  font-size: 17.19vw;
  color: #FFFFFF;
`;

export const SubHeadingContainerSubTitle = styled.div`
  font-family: SilverSouthSerif;
  font-size: 12.19vw;
  color: #FFFFFF;
  text-align: center;
  line-height: 15.00vw;
`;

export const HeroExtraInfoContainer = styled.div`
  height: 59.38vw;
  background-color: #F3F0EB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InfoButtonContainer = styled.a`
  margin-top: 4.688vw;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export const InfoButton = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 5.625vw;
  color: #464855;
  cursor: pointer;
`;

export const Info = styled.div`
  margin: 0 6.250vw 4.688vw;
  text-align: center;
  font-family: calluna; 
  font-weight: 300;
  font-size: 5.625vw;
  color: #000000;
  letter-spacing: 0;
  line-height: 8.750vw;
`;