import React from 'react';
import { connect } from 'react-redux';
import {
  Container, NumberContainer, Number,
  SubHeading, Divide,
} from './styled.js';

function Analytics({ productsListed, productsSold }) {
  return (
    <Container>
      <NumberContainer style={{ marginLeft: 186 }}>
        <Number>{productsListed}</Number>
        <SubHeading>listed</SubHeading>
      </NumberContainer>
      <Divide />
      <NumberContainer style={{ marginRight: 186 }}>
        <Number>${productsSold}</Number>
        <SubHeading>sold</SubHeading>
      </NumberContainer>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.shopReducer });

export default connect(mapStateToProps)(Analytics);