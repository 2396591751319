import React from 'react';
import { navigate } from '@reach/router';
import {
  Container, Title, Heading,
  Line, Dresses, DressImg,
  DressTitle, DressContainer,
} from './styled.js';
import FitFlare from './fit_flare.png';
import Ballgown from './ballgown.png';
import Aline from './a_line.png';
import Mermaid from './mermaid.png';
import Trumpet from './trumpet.png';
import Sheath from './sheath.png';

const dresses = [
  { img: FitFlare, title: 'Fit & Flare' },
  { img: Ballgown, title: 'Ballgown' },
  { img: Aline, title: 'A-Line' },
  { img: Mermaid, title: 'Mermaid' },
  { img: Trumpet, title: 'Trumpet' },
  { img: Sheath, title: 'Sheath' },
];

function ShopBy() {
  return (
    <Container>
      <Heading>
        <Title>Shop By Silhouette</Title>
      </Heading>
      <Dresses>
        {
          dresses.map(({ img, title }, index) => (
            <DressContainer onClick={() => navigate(`/shop?search=${title}`)} key={index}>
              <DressImg src={img} />
              <DressTitle>{title}</DressTitle>
            </DressContainer>
          ))
        }
      </Dresses>
    </Container>
  );
}

export default ShopBy;