import React from 'react';
import { navigate } from '@reach/router';
import { RichText } from 'prismic-reactjs';
import right_arrow from '../../../../images/right_arrow.svg';
import Image from '../../../ImageBasic';
import {
  Container, SubHeadingContainer, SubHeadingContainerTitle, HeroExtraInfoContainer, Info, InfoButtonContainer, InfoButton, SubHeadingContainerSubTitle,
} from './styled';
import background from './home.jpg';

function Hero({
  hero_bottom_text, hero_body_text,
  hero_top_text, hero_image, shop_button_text,
}) {
  return (
    <Container>
      <Image style={{ width: '100%', objectFit:'contain' }} src={background} />
      <SubHeadingContainer>
        <SubHeadingContainerTitle>{hero_top_text}</SubHeadingContainerTitle>
        <SubHeadingContainerSubTitle>{hero_bottom_text}</SubHeadingContainerSubTitle>
      </SubHeadingContainer>
      <HeroExtraInfoContainer>
        <Info><RichText render={hero_body_text} ></RichText></Info>
        <InfoButtonContainer onClick={() => navigate(hero_image.url)}>
          <InfoButton>{shop_button_text}</InfoButton>
          <img style={{ marginLeft: '3%' }} src={right_arrow} />
        </InfoButtonContainer>
      </HeroExtraInfoContainer>
    </Container>
  );
}

export default Hero;
