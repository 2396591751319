import React from 'react';
import { connect } from 'react-redux';
import { Title } from '../ShopBy/styled';
import Item from '../../../Item';
import { Container, Dresses } from './styled.js';

function Featured({ products, accountData }) {
  if (!Object.keys(products).length) {
    return <div />;
  }

  const { favoriteListings = [] } = accountData || {};
  return (
    <Container>
      <Title>FEATURED WEDDING DRESSES</Title>
      <Dresses>
        {Object.values(products).sort((a, b) => b.date - a.date).map((item) => <Item favorite={favoriteListings.includes(item.__id)} key={item.__id} {...item} />)}
      </Dresses>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.shopReducer, ...state.userReducer });

export default connect(mapStateToProps)(Featured);