import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-shrink: 0;
`;

export const HeroImage = styled.img`
  object-fit: cover;
  width: 67%;
`;

export const HeroTextContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: #F3F0EB;
  justify-content: flex-end;
  flex-direction: column;
`;

export const Content = styled.div`
  font-family: calluna; 
  font-weight: 300;
  font-size: 28px;
  color: #000000;
  letter-spacing: 0;
  line-height: 45px;
  margin: 0 30px;
  @media (max-width: 1500px) {
      font-size: 22px;
    }
    @media (max-width: 1200px) {
      font-size: 18px;
    }
    @media (max-width: 1000px) {
      font-size: 15px;
    }
`;

export const ExploreButton = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 23px;
  color: #464855;
  letter-spacing: 0;
  white-space: nowrap;
  margin-bottom: 18%;
  margin-top: 10%;
  @media (max-width: 1500px) {
    margin-top: 13%;
    margin-bottom: 10%;
  }
  @media (max-width: 1200px) {
    margin-top: 9%;
    margin-bottom: 10%;
  }
  cursor: pointer;
  text-decoration: none;
  width: 73%;
  margin-left: 30px;
`;

export const RightArrow = styled.img`
  margin-left: auto;
`;