import styled from 'styled-components';

export const Container = styled.div`
  height: 496px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 78px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 71px;
  margin-bottom: 55px;
`;
export const Line = styled.div`
  height: 2px;
  flex: 1;
  background-color: #7DBEC8;
`;

export const Title = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 55px;
  color: #124C56;
  letter-spacing: 0;
  text-align: center;
  line-height: 37px;
`;

export const DressImg = styled.img`
  width: 214px;
  object-fit: contain;
  margin-left: 22px;
  @media (max-width: 1300px) {
    width: 15vw;
    margin-left: 10px;
  }
`;

export const Dresses = styled.div`
  display: flex;
  justify-content: center;
  width:  100%;
  :first-child {
    margin-left: 0px;
  }
`;

export const DressTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #124C56;
  letter-spacing: 0;
  text-align: center;
`;

export const DressContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;