import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 112px;
  display: flex;
  flex-direction: column;
`;

export const Dresses = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > div {
    margin-bottom: 45px;
  }
`;