import styled from 'styled-components';

export const Container = styled.div`
  background-color: #E1CFCD;
  box-shadow: inset 0 0 14px 2px rgba(18,76,86,0.10);
  width: 100%;
  height: 193px;
  display: flex;
  flex-direction: column;
`;

export const NumberContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Number = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 900;
  font-size: 40px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-shadow: 1px 1px 0 #A68A87;
  margin-right: 15px;
`;

export const SubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-shadow: 1px 1px 0 #A68A87;
`;

export const Divide = styled.div`
  width: 100px;
  border: 1px solid #FFFFFF;
  box-shadow: 1px 1px 0 0 #A68A87;
  margin: 0 95px;
  align-self: center;
`;