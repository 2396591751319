import React from 'react';
import { RichText } from 'prismic-reactjs';
import right_arrow from '../../../../images/right_arrow.svg';
import BoxDialog from './BoxDialog';
import {
  Container, HeroImage, HeroTextContainer,
  Content, ExploreButton, RightArrow,
} from './styled';

function Hero({
  hero_bottom_text, hero_body_text,
  hero_top_text, hero_image:{ url }, shop_button_text,
}) {
  return (
    <Container>
      <BoxDialog top={hero_top_text} bottom={hero_bottom_text} />
      <HeroImage src={url} />
      <HeroTextContainer>
        <Content><RichText render={hero_body_text} /></Content>
        <ExploreButton href={'buy'}>
          {shop_button_text}
          <RightArrow src={right_arrow} />
        </ExploreButton>
      </HeroTextContainer>
    </Container>
  );
}

export default Hero;
