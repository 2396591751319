import React, { useContext } from 'react';
import Footer from '../Footer';
import { AppContext } from '../../../components/AppProvider';
import Hero from './Hero/';
import ShopBy from './ShopBy';
import Analytics from './Analytics';
import Featured from './Featured';

function Home() {
  const { data } = useContext(AppContext);
  return (
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Hero {...data} />
      <ShopBy {...data} />
      <Analytics {...data} />
      <Featured {...data} />
      <Footer showSignUp={false} />
    </div>
  );
}

export default Home;
