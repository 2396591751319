import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 112px;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`;

export const Dresses = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2vw;
  margin: 0 125px;
  @media (max-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;