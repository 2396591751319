import styled from 'styled-components';

export const Container = styled.div`
  left: calc(67% - 21.5vw);
  top: 12%;
  background-color: #D8C4C2;
  width: 43vw;
  height: 19vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
`;